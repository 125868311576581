@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
    margin:0 auto;
}

:root {
    --dark-main: #000;
    --dark-sub: #999;
    --light-main: #FFF;
    --light-subtle: rgba(255,255,255,0.25);
    --dark-subtle: rgba(255,255,255,0.07);
    --main-accent:#ed7901;
    --main-font: "Roboto", sans-serif;
    --menu-border-size:1px;
    --menu-spacing-side: 27px;
    --component-radius: 5px;
    --rm-audio-player-sortable-list-button-active:var(--main-accent)!important;
}

body,html {
    background:var(--dark-main);
    color:var(--light-main);
    font-family: var(--main-font);
}

.logo,
.menu-cart,
nav ul li,
.cart,
.cart img,
.cart span,
.menu,
.th-img,
.th-title,
.th-time,
.th-bpm,
.th-tags,
.th-button,
.th-tags span,
.cart-button,
.cb-icon,
.cb-price,
.footer-col,
.collection,
.cs-left,
.cs-right,
.ci-left,
.ci-right,
.cil-left,
.cil-right,
.abt-left,
.abt-right,
.ap-left,
.ap-right,
.apl-img,
.apl-title,
.social-icon {
    display:inline-block;
    vertical-align:middle;
    box-sizing: border-box;
}


.logo,
.menu-cart {
    width:50%;
}

.menu-cart {
    text-align:right;
}

.cart img,
.cb-icon img {
    width:17px;
    margin-top:-7px;
}
.cb-icon img {
    margin-top:0;
}
.cart span {
    width:calc(100% - 17px);
    font-size:14px;
    font-weight:500;
    padding-left:10px;
}

.cart:hover {
    cursor:pointer;
}

header,
section,
footer {
    position:relative;
    width:100%;
    box-sizing: border-box;
}

header {
    position:fixed;
    top:0;
    left:0;
    padding:15px 10px;
    background:var(--dark-main);
    border-bottom:1px var(--dark-subtle) solid;
    z-index:2;
}
header h1,
footer h2 {
    font-size:32px;
    font-weight:700;
    text-transform:uppercase;
    letter-spacing:1px;
}

header h1 a {
    color:var(--light-main);
    text-decoration:none;
}

footer h2 {
    font-size:27px;
}

nav ul li a,
nav ul li span {
    color:var(--light-main);
    text-decoration:none;
    padding:0 var(--menu-spacing-side) 15px;
    font-weight:300;
    font-size:14px;
    border-bottom:var(--menu-border-size) transparent solid;
    transition:400ms;

}
nav ul li a:hover,
nav ul li span:hover {
    border-bottom:var(--menu-border-size) var(--light-subtle) solid;
    cursor:pointer;
}

.cart {
    border-left:var(--menu-border-size) var(--light-subtle) solid;
    padding:5px var(--menu-spacing-side);
}

.splash-component,
.content-title,
.collection-splash {
    top:68px;
}
.splash-component {
    height:750px;
}
.content-title {
    padding:35px 0;
    text-align:center;
}
.content-title h2 {
    color:var(--light-main);
    font-size:43px;
    font-weight:500;
    letter-spacing:2px;
}

.profile {
    text-align:center;
    padding:75px 0 35px;
}
.profile img {
    width:250px;
    border-radius:50%;
}

.content {
    text-align:center;
}
.line {
    font-size:18px;
    line-height:27px;
    margin-bottom:25px;
    font-weight:300;
}
.sc-background {
    position:relative;
    width:100%;
    height:100%;
    background-repeat:no-repeat;
    background-position:100%;
    background-color:#ed3700;
}

.fade-gradient {
    position:absolute;
    bottom:0;
    left:0;
    width:100%;
    height:250px;
    background: rgb(0,0,0);
    background: linear-gradient(0deg, rgba(0,0,0,1) 41%, rgba(0,0,0,0.4) 79%, rgba(255,255,255,0) 100%);
}

.view-container,
.image-container,
.close-pv,
.cart-item-container {
    position:relative;
    margin:0 auto;
    width:1200px;
}

.table,
.table-header,
.table-row {
    position:relative;
    width:100%;
}

.th-img {
    width:50px;
}

.th-img img {
    width:100%;
    border-radius:var(--component-radius);
}

.th-bpm,
.th-time {
    width:75px;
}

.th-button {
    width:100px;
    text-align:right;
}

.th-title,
.th-tags {
    width:calc(50% - 150px);
    text-align:left;
}

.ii-input .button,
.button a {
    display:inline-block;
    margin:35px auto;
    background:var(--dark-subtle);
    padding:15px 25px;
    color:var(--light-main);
    text-decoration:none;
    font-size:13px;
    letter-spacing:1px;
    border-radius:var(--component-radius);
    transition:400ms;
}
.ii-input .button:hover,
.button a:hover {
    cursor:pointer;
    background:var(--light-subtle);
}
.ii-input .button {
    margin:0;
}

.filters {
    position:relative;
    width:100%;
    padding:0 0 15px;
    text-align:right;
}
.filter-select {
    display:inline-block;
    vertical-align: middle;
    width:200px;
    font-size:18px;
    border-radius:var(--component-radius);
    background:var(--dark-subtle);
    color:var(--light-subtle);
    padding:7px 10px;
}

input.search {
    position:relative;
    width:100%;
    border-radius:var(--component-radius);
    padding:10px 15px;
    font-size:18px;
    outline:none;
    border:1px var(--light-subtle) solid;
    margin:0 auto 50px;
    background:var(--dark-subtle);
    color:var(--light-main);
    box-sizing: border-box;
}

.header-space {
    margin-top:35px;
}
.header-space-more {
    margin-top:100px;
}

.bbottom {
    border-bottom:1px var(--dark-subtle) solid;
    padding-bottom:35px;
}

.table-header {
    font-size:12px;
    color:var(--dark-sub);
    text-transform: uppercase;
    letter-spacing:2px;
}

.table-row {
    padding:15px 0;
    border-bottom:var(--menu-border-size) var(--light-subtle) solid;
    transition:400ms;
}

.table-row .th-title,
.table-row .th-time,
.table-row .th-bpm,
.table-row .th-bpm {
    font-weight:300;
}

.th-message {
    font-size:14px;
    color:var(--light-subtle);
    padding-top:10px;
}
.th-message.no-margin {
    padding-top:0;
}
.th-title {
    padding-left:25px;
}

.table-row .th-title {
    font-weight:500;
}

.th-tags span {
    background:var(--light-subtle);
    color:var(--light-main);
    padding:5px 15px;
    margin:0 5px;
    border-radius:var(--component-radius);
    font-size:12px;
    font-weight:300;
    text-transform: uppercase;
    transition:400ms;
}
.th-tags span:hover {
    background:var(--dark-subtle);
}
.th-tags span:first-child {
    margin-left:0;
}

.table-row .th-title:hover {
    cursor:pointer;
    text-decoration: underline;
}

.cart-button,
.ab-top .cb-button {
    border:var(--menu-border-size) var(--light-subtle) solid;
    width:100%;
    text-align:center;
    display:inline-block;
    padding:7px 0;
    box-sizing: border-box;
    border-radius:var(--component-radius);
    transition:400ms;
}
.cart-button:hover {
    cursor:pointer;
    background:var(--dark-subtle);
}

.apl-title .cart-button {
    transform:scale(0.7);
    margin:0 0 0 -15px;
}

.abt-left {
    width:calc(100% - 100px);
}
.abt-right {
    width:100px;
}
.abt-right .cb-button {
    width:100%;
}
.abt-right .cb-icon img {
    margin-top:0;
}

.ab-top .cb

.cb-icon img {
    width:14px;
    margin-top:1px;
}
.cb-price {
    padding-left:10px;
    font-size:14px;
}


footer {
    margin-top:75px;
    border-top:var(--menu-border-size) var(--dark-subtle) solid;
    padding:50px 0 125px;
}

.footer-col {
    padding:0 50px 0 0; 
    vertical-align:top;
    width:calc(50% - 325px);
}
.footer-col:first-of-type {
    width:325px;
}

.footer-col nav {
    padding-top:4px;
}
.footer-col nav ul li {
    display:block;
    padding:0 0 25px;
}
.footer-col nav ul li a {
    border-bottom:0;
    padding:0;
}
.footer-col nav ul li a:hover {
    border-bottom:0;
    text-decoration: underline;
}

.social {
    position:relative;
    padding:0 0 15px;
}
.social-icon {
    margin:0 2px;
}

.copyright {
    font-size:11px;
    color:var(--light-subtle);
    font-weight:400;
    padding:0 0 15px;
}

.copyright a {
    color:var(--light-subtle);
}

.audio-player {
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    background:var(--dark-main);
    padding:10px 0;
    z-index:3;
}
.audio-player.v1 {
    padding:25px 0;
}

.ap-left {
    width:200px;
}
.ap-right {
    width:calc(100% - 200px);
}

.apl-img,
.apl-title {
    vertical-align: top;
}

.apl-img {
    width:75px;
}
.apl-img img {
    width:100%;
    border-radius:var(--component-radius);
}

.apl-title {
    padding-left:10px;
}

.apl-title span {
    font-size:10px;
    text-transform:uppercase;
}

.rhap_main,
.rhap_progress-section {
    box-sizing: border-box;
    width:calc(100% - 150px);
    padding:0 35px 0 0;
}
.rhap_container {
    background:var(--dark-main);
    padding:0 15px 15px;
}
.rhap_progress-container {
    box-sizing: border-box;
    position:relative;
    padding:0;
    width:100%;
}
.rhap_volume-container {
    width:150px;
}



.interface-grid {
    background:#000!important;
}

.track-current-time {
    color:var(--main-accent)!important;
}
.rm-player-progress,
.rm-player-progress-handle {
    background-color:var(--main-accent)!important;
}
.drawer-trigger-wrapper svg,
.drawer-container {
    display:none;
    color:var(--main-accent)!important;
}

.album-cover-wrapper img,
.artwork-container img {
    border-radius:var(--component-radius);
}
.discography {
    position:relative;
    width:100%;
    margin:35px auto;
}
.collection {
    width:calc(25% - 10px);
    margin:15px 5px;
    padding:25px 25px 0;
    border:1px var(--dark-subtle) solid;
}
.collection.even-space {
    padding:25px;
    vertical-align: top;
}
.collection:hover {
    background:var(--dark-subtle);
    cursor:pointer;
}
.collection-image {
    width:100%;
}
.collection-image img {
    width:100%;
    border-radius:var(--component-radius);
}

.collection-label {
    position:relative;
    padding:15px 0;
    text-transform: uppercase;
    font-size:14px;
}

a .collection-label,
a .collection span,
.collection-label a,
.collection-label,
.collection span a,
.collection a span {
    display:block;
    letter-spacing:1px;
}
.collection a {
    color:var(--light-subtle);
    text-decoration:none;
    transition:400ms;
}
.collection:hover a {
    color:var(--light-main);
}

.collection-splash {
    padding:50px 0 25px;
    border-bottom:1px var(--dark-subtle) solid;
}
.cs-left,
.cs-right {
    vertical-align:top;
}
.cs-left {
    width:250px;
}
.cs-left img {
    width:100%;
}
.cs-type {
    text-transform:uppercase;
    font-size:13px;
    letter-spacing:1px;
}
.cs-right {
    width:calc(100% - 250px);
    padding:35px 0 0 50px;
}
.cs-right h2 {
    padding:10px 0 20px;
    font-size:77px;
}

.photo-viewer {
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background:rgba(0,0,0,0.85);
    z-index:10;
}

.photo-viewer .view-container {
    display:table;
    width:100%;
    height:100%;
}

.photo-viewer .view-container .image-view {
    display: table-cell;
    vertical-align: middle;
    position:relative;
}

.image-view img {
    position:relative;
    max-width:100%;
    max-height:90vh;
}

.close-pv {
    width:100px;
    font-size:13px;
    text-transform:uppercase;
    padding:15px 0;
    color:var(--dark-sub);
    border:1px var(--dark-subtle) solid;
    margin:15px auto;
    text-align:center;
}
.close-pv:hover {
    cursor:pointer;
    color:var(--light-main);
    background:var(--dark-subtle);
}

.cart-item-container {
    background:var(--dark-main);
    min-height:450px;
    border:var(--menu-border-size) var(--dark-subtle) solid;
    box-sizing: border-box;
    padding:35px;
    text-align:left;
}

.account-container .cart-item-container {
    width:450px;
    text-align:center;
}


.ci-left,
.ci-right,
.cil-left,
.cil-right {
    vertical-align: top;
}

.ci-left,
.ci-right {
    width:50%;
}
.cil-left {
    width:200px;
}
.cil-right {
    padding:15px 0 0 15px;
}
.cil-left img {
    border-radius:var(--component-radius);
}

.cil-right .cs-type {
    padding-bottom:10px;
}
.cil-right h2 {
    font-size:32px;
}

.cil-right .cs-info {
    padding-top:15px;
    font-weight:300;
    font-size:15px;
}

.cs-info span {
    text-decoration:underline;
}
.cs-info span:hover {
    cursor: pointer;
}

.ci-right h3 {
    font-weight:300;
}

.agreement-block {
    position:relative;
    box-sizing: border-box;
    padding:15px;
    width:100%;
    border-radius:var(--component-radius);
    background:var(--dark-subtle);
    border:var(--menu-border-size) var(--light-subtle) solid;
}

.input-item {
    position:relative;
    width:100%;
    margin:25px auto;
    text-align:left;
}
.input-item.center {
    text-align:center;
}
.ii-label {
    font-size:12px;
    text-transform:uppercase;
    font-weight:200;
    box-sizing: border-box;
    padding-left:7px;
}
.ii-input input {
    width:100%;
    margin:5px 0;
    font-size:16px;
    border:1px var(--dark-subtle) solid;
    background:transparent;
    color:var(--light-main);
    padding:10px 10px;
    box-sizing: border-box;
    border-radius:var(--component-radius);
}
.input-item.error input {
    border:1px #FF0000 solid;
}

.error-statement {
    margin:7px auto;
    font-size:12px;
    color:#FF0000;
}

.youtube-container {
    display: flex;
    justify-content: center;
}


.youtube-container iframe {
    aspect-ratio: 16 / 9;
    width: 100% !important;
}

.abb-text {
    font-size:14px;
    margin-bottom:15px;
}
.abb-text:hover {
    cursor:pointer;
}
.agreement-text {
    position:relative;
    overflow:scroll;
    overflow-x:hidden;
    height:350px;
    box-sizing: border-box;
    padding:0 15px 0 0;
}
.agreement-line {
    position:relative;
    margin:7px auto;
    font-size:12px;
    line-height:16px;
    text-align: justify;
}

@media only screen and (max-width:1467px){
    .logo {
        width:30%;
    }
    .menu-cart {
        width:70%;
    }
}

@media only screen and (max-width:1267px){
    .view-container,
    .image-container,
    .close-pv,
    .cart-item-container {
        width:1000px;
    }
}

@media only screen and (max-width:1067px){
    .view-container,
    .image-container,
    .close-pv,
    .cart-item-container {
        width:967px;
    }
    .logo {
        width:20%;
    }
    .menu-cart {
        width:80%;
    }
    header h1 {
        font-size:22px;
    }
    nav ul li a,
    nav ul li span {
        padding:0 15px 15px;
    }
}

@media only screen and (max-width:1003px){
    .view-container,
    .image-container,
    .close-pv,
    .cart-item-container {
        width:96%;
    }
    .ap-left,
    .ap-right {
        width:100%;
        display:block;
    }
}

nav ul li.mobile  {
    display:none;
}
@media only screen and (max-width:967px)
{
    .rhap_main, .rhap_progress-section {
        width:calc(100% - 30px);
    }
    .splash-component, .content-title, .collection-splash {
        top:50px;
    }
    nav ul li {
        display:none;
    }
    nav ul li.mobile {
        display:inline-block;
    }
    .cart span {
        display:none;
    }
    .cart img, .cb-icon img {
        width:12px;
        margin-top:0;
    }
    .cart {
        padding:0 15px;
    }
}